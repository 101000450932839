@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('react-loading-skeleton/dist/skeleton.css');

@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  -tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media print {
  @page {
    size: landscape;
  }
  body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.fc-agenda-slots td div { height: 10px !important; }


.react-datepicker__navigation--previous, .react-datepicker__navigation--next {
  margin-top: 5px;
}

.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important;
}


/* button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline: none !important; 
}

.btn {
  @apply bg-red-500 text-white font-bold py-2 px-4 rounded
}

.btn:focus {
  @apply outline-none
} */

button:focus, select:focus, .text-input:focus { outline: none !important; }

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}